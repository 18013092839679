import Hero from "../components/ui/hero/hero";
import Videos from "../components/ui/videos/videos";

export default function Home() {
  return (
    <>
      <Hero />
      <Videos />
    </>
  );
}
